import React from 'react';
import styled from 'styled-components';

const ProductContainer = styled.div`
  margin: 10px;
  text-align: left;
  width: calc(33.333% - 20px);

  @media (max-width: 768px) {
    width: calc(50% - 20px);
  }

  @media (max-width: 480px) {
    width: calc(100% - 20px);
  }
`;

const ImagePlaceholder = styled.div`
  width: 100%;
  padding-top: 100%; // This makes the div square by setting the height relative to the width
  background-color: #ccc;
  margin-bottom: 10px;
  position: relative;
`;

const ImageContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ProductInfo = styled.div`
  font-size: 28px;
`;

const ProductName = styled.div`
  font-weight: bold;
`;

const ProductHeading = styled.div`
  color: gray;
`;

const ProductPrice = styled.div`
  color: green;
`;

const Product = ({ name, heading, price, image_url }) => {
  return (
    <ProductContainer>
      <ImagePlaceholder>
        <ImageContent>
          <img src={image_url} alt={name} style={{ width: '100%', height: 'auto' }} />
        </ImageContent>
      </ImagePlaceholder>
      <ProductInfo>
        <ProductName>{name}</ProductName>
        <ProductHeading>{heading}</ProductHeading>
        <ProductPrice>${price}</ProductPrice>
      </ProductInfo>
    </ProductContainer>
  );
};

export default Product;
