import React from 'react';
import styled from 'styled-components';
import Product from './Product';

const SupermarketContainer = styled.div`
  margin-bottom: 100px;
`;

const ProductList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const TotalPrice = styled.div`
  font-size: 30px;
  font-weight: bold;
  margin-top: 20px;
  color: green;
`;

const SupermarketTitle = styled.div`
  font-size: 48px;
  font-weight: bold;
`

const Supermarket = ({ name, products, totalPrice }) => {
  return (
    <SupermarketContainer>
      <SupermarketTitle>{name.toUpperCase()}</SupermarketTitle>
      <ProductList>
        {products.map((product, index) => (
          <Product
            key={index}
            name={product.name}
            heading={product.heading}
            price={product.selling_price}
            image_url={product.image_url}
          />
        ))}
      </ProductList>
      <TotalPrice>TOTAL: ${totalPrice}</TotalPrice>
    </SupermarketContainer>
  );
};

export default Supermarket;
