import React, { useState, useCallback } from "react";
import debounce from 'lodash/debounce';
import './SearchBar.css';

const SearchBar = ({ setSelectedProduct }) => {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);

  // Define the debounced function
  const fetchResults = async (searchQuery) => {
    if (searchQuery.length >= 3) {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await fetch(`${apiUrl}/products?product=${searchQuery}`);
        const data = await response.json();
        setResults(data);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    } else {
      setResults([]);
    }
  };

  // Use useCallback to memoize the debounced function
  const debouncedFetchResults = useCallback(debounce(fetchResults, 500), []);

  // Update the query state and trigger the debounced fetch
  const handleInputChange = (e) => {
    const newQuery = e.target.value;
    setQuery(newQuery);
    debouncedFetchResults(newQuery);
  };

  const handleSelectProduct = (product) => {
    setSelectedProduct(product);
    setResults([]);
    setQuery("");
  };

  return (
    <div className="search-bar">
      <input
        type="text"
        value={query}
        onChange={handleInputChange}
        placeholder="Buscar un producto..."
      />
      {results.length > 0 && (
        <ul className="results-list">
          {results.map((result, i) => (
            <li key={i} onClick={() => handleSelectProduct(result)}>
              <img src={result.image_url} alt={result.name} width="50" />
              <div>
                <strong>{result.name}</strong>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchBar;
