import React from 'react';
import { Chart } from 'react-charts';

function PriceChart({ productId }) {
	const [pricesData, setPricesData] = React.useState([])
	const [productData, setProductData] = React.useState({})
	const [error, setError] = React.useState(null)
	const [loading, setLoading] = React.useState(true)

  const formatDate = (date) => {
    const d = date.getDate() + 1;
    const m = date.getMonth() + 1;
    const y = date.getFullYear();
    return `${d}/${m}/${y}`;
  }

  const defaultColors =  [
    "#ff0010",  // Rojo
    "#0032ff", // Azul
    "#3aff00", // Verde
    "#ffae00", // Naranja
    "#00ffcd", // Turquesa
    "#f0ff00", // Amarillo
  ]

  const renderLastPrices = () => {
    return (
      <div>
        <h3><u>Ultimos precios</u></h3>
        {
          pricesData.map((elem, i) => {
            if (elem.data.length > 0) {
              return (
                <p key={i}>
                  <span style={{
                    color: defaultColors[i], 
                    fontSize: '20px',
                    marginRight: '5px'
                  }}>
                    •
                  </span>
                  {elem.label.toUpperCase()}: ${elem.data[0]["secondary"]} ({formatDate(elem.data[0]["primary"])})
                </p>
              )
            }
            return <div key={i}></div>
          })
        }
      </div>
    )
  }

	React.useEffect(() => {
    const fetchData = async () => {
      if (productId == null) {
        return null
      }

      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await fetch(`${apiUrl}/products/${productId}`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();
				const transformedData = result.supermarket_prices.map(item => {
					return {
						label: item.supermarket,
						data: item.product_prices.map(priceItem => {
							return {
								primary: new Date(priceItem.date),
								secondary: priceItem.price
							};
						}),
					};
				});
        setPricesData(transformedData);
        console.log(transformedData)
				setProductData({
					name: result.name,
					externalId: result.external_id,
					imageUrl: result.image_url,
				})
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    }

		fetchData();
  }, [productId]);

  const primaryAxis = React.useMemo(
    () => ({
        getValue: datum => datum.primary
    }),
    []
  )

  const secondaryAxes = React.useMemo(
    () => [
        {
            getValue: datum => datum.secondary
        }
    ],
    []
  )

	if (loading) {
    return <div></div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
      <div style={{textAlign: 'center'}}>
        <img src={productData.imageUrl} style={{width: 'auto', height: '400px'}} alt={productData.externalId} />
        <h2 style={{padding: '0 15%'}}>{productData.name}</h2>
        <h3>EAN: {productData.externalId}</h3>
        {renderLastPrices()}
      </div>
      <div
        style={{
          height: '400px',
          width: '70%',
          marginBottom: '40px'
        }}
      >
        <Chart options={{
          defaultColors,
          data: pricesData, 
          primaryAxis, 
          secondaryAxes
        }} />
      </div>
		</div>
  );
}

export default PriceChart;
