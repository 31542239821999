import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Supermarket from './Supermarket';
import { useParams } from 'react-router-dom';

const ComparisonContainer = styled.div`
  text-align: center;
`;

const SupermarketComparison = () => {
  const { uuid } = useParams();
  const [supermarkets, setSupermarkets] = useState([]);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await fetch(`${apiUrl}/shopping_list/${uuid}`);
        const data = await response.json();
        setSupermarkets(data.supermarket_products);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []);

  return (
    <ComparisonContainer>
      {supermarkets.map((supermarket, index) => (
        <Supermarket
          key={index}
          name={supermarket.name}
          products={supermarket.products}
          totalPrice={supermarket.total_cost}
        />
      ))}
    </ComparisonContainer>
  );
};

export default SupermarketComparison;
