import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import PriceChart from './PriceChart';
import { useParams } from 'react-router-dom';

const Container = styled.div`
  text-align: center;
  padding-top: 20px;
`;

const ProductDetailPage = () => {
  const { externalId } = useParams();

  return (
    <Container>
      <PriceChart productId={externalId} />
    </Container>
  );
};

export default ProductDetailPage;
