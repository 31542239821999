import React, {useState} from 'react';
import SupermarketComparison from './components/SupermarketComparison';
import PriceChart from './components/PriceChart';
import styled from 'styled-components';
import SearchBar from './components/SearchBar';
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProductDetailPage from './components/ProductDetailPage';

const AppContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;


const MainPage = () => {
  const [selectedProduct, setSelectedProduct] = useState(null);

  return (
    <AppContainer>
      <SearchBar setSelectedProduct={setSelectedProduct} />
      <PriceChart productId={selectedProduct && selectedProduct.external_id} />
    </AppContainer>
  ); 
}


const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/shopping/:uuid" element={<SupermarketComparison/>} />
        <Route path="/product/:externalId" element={<ProductDetailPage/>} />
      </Routes>
    </Router>
  );
};

export default App;
